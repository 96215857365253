import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

import PageHelmet from "../../components/Helmet";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Banner from "../../components/Banner";
import Summary from "../../components/Summary";
import Video from "../../components/Video"

const webisodes = [
    "https://www.youtube.com/watch?v=OrL2y2hQWHo&t=35s",
    "https://www.youtube.com/watch?v=TfiXT0cpYUw",
    "https://www.youtube.com/watch?v=omw6ny5HA08",
    "https://www.youtube.com/watch?v=sOJmCBqCI6M&t=5s",
    "https://www.youtube.com/watch?v=e2WmEHZMq3I",
    "https://www.youtube.com/watch?v=WC6OSX-VLGE&t=93s",
    "https://www.youtube.com/watch?v=eUQqNFrm1h8",
    "https://www.youtube.com/watch?v=ixynAGzDA8U",
    "https://www.youtube.com/watch?v=yA_Gx0noogM",
    "https://www.youtube.com/watch?v=hF7jwpKqJSw",
    "https://www.youtube.com/watch?v=97GV5MfolgU&t=13s",
    "https://www.youtube.com/watch?v=oqin56-hZtE",
    "https://www.youtube.com/watch?v=-ql6tvaWIr8&t=9s",
    "https://www.youtube.com/watch?v=XMbCwARJHek",
    "https://www.youtube.com/watch?v=f2UhGknAndQ&t=1s",
    "https://www.youtube.com/watch?v=8Hned_-YMOg&t=32s",
    "https://www.youtube.com/watch?v=HLPcRQ2T7aE",
    "https://www.youtube.com/watch?v=95cC2WVn6R4",
    "https://www.youtube.com/watch?v=uXneq3o1kvk&t=8s",
    "https://www.youtube.com/watch?v=6r-2lUWNCU4&t=94s",
    "https://www.youtube.com/watch?v=dFYtklA63Fc",
    "https://www.youtube.com/watch?v=rYgu4YMOCkY&t=2s",
    "https://www.youtube.com/watch?v=ROpkfP5Kzko"
];

function FilmPage(props) {
    return class PortfolioDetails extends Component {
        constructor () {
            super()
            this.state = {
              isOpen: false
            }
            this.openModal = this.openModal.bind(this)
        }
        openModal () {
            this.setState({isOpen: true})
        }
        render(){
            return(
                <React.Fragment>
                    
                    <PageHelmet pageTitle='Portfolio Details' />

                    <Header logo="symbol-dark" color="color-black"/>
                    
                    <Banner title={props.title} type={props.type} bg={props.bg} />

                    <Summary summary={props.summary} type={props.type} title={props.title}/>

                    <Video title="Episode 1" videoImage={props.videoImage} videoId={props.videoId} channel={props.channel} />

                    <div className="rn-portfolio-details ptb--80 bg_color--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="portfolio-details">
                                        <div className="inner">
                                            <h2>Other Episodes</h2>
                                            <ol>
                                                {webisodes.map((link, index) => (
                                                    <li key={index}>
                                                        <a href={link} target="_blank" rel="noopener noreferrer">
                                                            {`Webisode ${index + 1}`}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Start Back To Top */}
                    <div className="backto-top">
                        <ScrollToTop showUnder={160}>
                            <FiChevronUp />
                        </ScrollToTop>
                    </div>
                    {/* End Back To Top */}
                    
                    <Footer />  


                </React.Fragment>
            )
        }
    }
}

export default FilmPage;
