import React from "react";

const synopsis = <p>

<p>Tragedy has struck the Wilson family. While sailing up the coast, on his way home for Christmas, their widowed father’s yacht has disappeared. Brad, the eldest, a recently separated corporate lawyer, flies home from Toronto to Vancouver to take charge of the situation. This story takes place in the Wilson family home over the week of Christmas as his brother, his sister, and Carly, the black sheep of the family, are there. It is a grand house, thanks to the Wilson family business called “Angel Inc.”, which has done very well selling Christmas lights. Brad tries to sort things out like only a first born can but, at the reading of the will, there is a twist they never saw coming which causes this short return to become a life changing experience for all.</p>

<p>This is a group of young adults who have lost both parents, so relationships are wound tight. Complications pile up as the week goes on. The house needs to be sorted out. Legal matters surface. The company needs attention and Uncle Jack wants to sell. Also, they discover the Wilson parents weren’t as well-off that they pretended to be and have left their kids a house that they can’t afford to keep.</p>

<p>Brad is at the center of all this drama, being his father’s son, but if only his father had set a better example. Past stories are stirred-up, future choices are faced, and Carly is at the center of it all. What caused her to leave? How has she suddenly changed? What does it have to do with Christmas? Beliefs are challenged, relationship are tested, truths are uncovered, and it all comes to a head the night before the big day.</p>

</p>

export default synopsis;