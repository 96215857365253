import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

import Header from "../components/Header";
import Footer from "../components/Footer"
import PortfolioList from "../components/PortfolioList";
import Contact from "../components/Contact";
import Helmet from "../components/Helmet";

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Writer Filmmaker Father',
        // title: 'Hello, I’m <span>Nancy</span> Welcome to my World.',
        title: 'Hello, I’m <span>Brook Smith</span>! <br> Nice to meet you!',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]


class PersonalPortfolio extends Component{
    render(){

        let title = 'About Me',
        description = "Stories, to me, are allegories that teach and entertain at a depth that cannot always be conveyed by facts and figures. Whether their purpose is to reflect our world, or help transform it, is a question for philosophers, but I won’t deny that the latter has always been a great motivation for me. Age has sobered this somewhat, as I have found art not only reflects the artist but also helps transform them. This is its ultimate power and, as a result, my palette has widened, and my perspective deepened."
        let description2 = "My narratives are about everyday characters who find themselves in extraordinary situations. These characters are often required to make difficult choices, life-changing, sink or swim, choices. In my mind, they always choose to swim. I am an optimist and I believe in humanity. Their choices may not be the most obvious or predictable in today’s world, but they are what I believe is best for the character - because the character is, ultimately, me. "

        return(
            <Fragment> 
                <Helmet pageTitle="Personal Portfolio" />
                
                <Header logo="symbol-dark" color="color-black" main={true}/>
                
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--36" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title" dangerouslySetInnerHTML={{__html: value.title}}></h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area about-position-top pb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/dad/images/dad/headshot.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner pt--100">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                            <p className="description">{description2}</p>
                                        </div>
                                        {/* <div className="row mt--30">
                                            <TabTwo tabStyle="tab-style--1" />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Projects Area */}
                <div className="portfolio-area pb--60 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55 mb_sm--0">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">My Featured Projects</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/more-projects"><span>View More</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Projects Area */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area pb--120 bg_color--1">
                    <Contact />
                </div>
                {/* End Portfolio Area */}

                <Footer />

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}

export default PersonalPortfolio;