const AllContent = [
    {
        image: 'image-drowning_man',
        category: 'Novel',
        title: 'Drowning Man',
        link: '/drowning-man',
    },
    {
        image: 'image-good_game',
        category: 'Novel/Script',
        title: 'Good Game',
        link: '/good-game'
    },
    {
        image: 'image-the_screen',
        category: 'Script',
        title: 'The Screen Series',
        link: '/the-screen'
    },
    {
        image: 'image-the_present',
        category: 'Script',
        title: 'The Present',
        link: '/the-present'
    },
    {
        image: 'image-matthew_24',
        category: 'Script',
        title: 'Matthew 24',
        link: '/matthew24'
    },
    {
        image: 'image-father_and_son',
        category: 'Feature Length Film',
        title: 'Father and Son',
        link: '/father-and-son',
    },
    {
        image: 'image-the-fourth-wall',
        category: 'Web Series',
        title: 'The Fourth Wall',
        link: '/the-fourth-wall'
    },
    {
        image: 'image-y',
        category: 'Short Film',
        title: 'Y',
        link: '/y'
    },
    {
        image: 'image-mancaved',
        category: 'Short Film',
        title: 'Mancaved',
        link: '/mancaved'
    },
    {
        image: 'image-free-ride',
        category: 'Short Film',
        title: 'Free Ride',
        link: '/free-ride'
    },
    {
        image: 'image-ambition',
        category: 'Short Film',
        title: 'Ambition',
        link: '/ambition'
    },
    {
        image: 'image-my-head',
        category: 'Short Film',
        title: 'My Head',
        link: '/my-head'
    },
]

export default AllContent;