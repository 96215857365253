import React from "react";

const synopsis = <p>

<p>It’s a four-part movie franchise (or an eight-part unlimited series) set in a two-dimensional world on screen. The characters are conscious of a world out there, behind the lens, but unsure of its reach and power.</p>

<p>Charlie MacBride, the protagonist, is unique because he has a narrator in the second person, who he talks to. This voice confirms to him the existence of a world behind the fourth wall.  The trouble is this character is the same actor, possibly in a sound stage somewhere, so it could be a trick. Neither character fully knows what’s going on, so they argue and debate as they embark on their journey.</p>

<p>The primary antagonists here are the commercial interests behind the scenes who don’t want the characters to break the fourth wall for fear they will undermine their narrative. They want the characters to be desperate and afraid, so they are more able to be manipulated into telling captivating stories.</p>

<p>It is a ‘meta’ concept that looks at life, and the world we live in, as if it were a big movie on screen. The question, of course, is who is actually in control of the narrative?</p>

<p>Some characters believe there is a writer putting words in their mouths. Others believe they create their own opportunities through their own talent and will. Some are convinced their world on screen is a complete bust and no one is watching.  Others believe it is utterly real.</p>

<p>Charlie meets Megan LaRose, his love interest, who has a narrator of her own.  Her narrator, however, is in the first person, so she just listens to her “inner voice” and it tells her what to do. As a result, she gets manipulated into a menacing plot from which she can’t escape.</p>

<p>Charlie falls for this enigmatic woman, enters her story to help her out and, hence, their journey begins.</p>

<h3>Part 1 - "The Screen"</h3>

<p>Charlie enters Megan’s story about a miraculous drug that, when taken, transforms the person into a supermodel for a few hours. It also has terrible side effects. Megan has stolen the formula and is being chased by bad actors. Charlie reluctantly takes on the role of hero in her story in order to, hopefully, steer the plot towards a romantic resolve and save their story.</p>

<h3>Part 2 - "The Narrator"</h3>

<p>An action/comedy spawned by Megan accidently becoming pregnant, follows the narrator’s perspective on his lead characters’ trials and tribulations as they struggle to build a future in this on-screen world. It straddles two realities - Charlie pursuing his aspirations of being a writer and his narrator’s CGI adventure dream, where aliens have taken over the world. </p>

<h3>Part 3 - “The Script”</h3>

<p>While living with Megan and their two kids, Charlie’s world is suddenly turned upside down when he is hurled into a thriller involving a deadly trade exchange on the dark web.  A further twist happens when he is informed by a mysterious woman that this is not actually his real story but a devious manipulation of the plot by dark forces off screen. She claims to have access to the true script which could possibly lead them to a far more enlightening and fulfilling journey.</p>

<h3>Part 4 - “The Writer”</h3>

<p>Charlie, Megan and family follow the new script to Guatemala but forces, in front and behind the screen, wrestle to control the narrative drive. Each have agents on screen aligned to take this story in their preferred direction and who to trust and who not to trust becomes paramount. It is not until Charlie cracks the true meaning of this new script that he manages to access his heroic qualities and save the day.</p>

</p>

export default synopsis;