import info from "./info";
import logline from "./logline";
import synopsis from "./synopsis";

import WritingPage from "../WritingPage";

const props = {
    title: info.title,
    type: info.type,
    bg: "46",
    link: '/good-game',
    logline: logline,
    synopsis: synopsis
}

export default WritingPage(props);
