import info from "./info";
import summary from "./summary";

import FilmPage from "../FilmPage";

const props = {
    title: info.title,
    type: info.type,
    bg: "45",
    link: '/free-ride',
    summary: summary,
    videoId: '144673424',
    videoImage: '/assets/images/dad/images/video/freeride.jpg'
}

export default FilmPage(props);
