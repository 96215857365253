import info from "./info";
import summary from "./summary";

import FilmPage from "../FilmPage";

const props = {
    title: info.title,
    type: info.type,
    bg: '47',
    link: '/mancaved',
    summary: summary,
    videoId: '114692756',
    videoImage: '/assets/images/dad/images/video/mancaved.jpg'
}

export default FilmPage(props);
