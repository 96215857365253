import React from "react";

const synopsis = <p>

    <p>
        Archie and Quinn Davies, two novice hikers, get lost in the woods. Quinn’s absentee father, Archie, then falls and breaks his ankle during an argument and these two are now stranded alone together deep in the mountains of British Columbia. Having not seen each other for three years, with lots of unresolved issues to deal with, they are forced to try and survive this ordeal while somehow connecting on a more personal level. However, with wolves in the area, little food, and no water, it is not long until the question becomes does Quinn go for help or stay and risk his life to protect his dad.
    </p>

    <p>
        Shot on Bowen Island, B.C., with a budget of about $5,000 and a single “swiss army knife” of a crew member, it uses footage and music from Videoblocks and Audioblocks websites, as well as lots of creative initiative, to tell this heart-warming tale.
    </p>

    <p>
        As the producer, writer, director, actor, editor, second unit camera, and sound person, the education I received from this experience was immense. My friend was the D.O.P. and my eldest son played the part of my son. It took two weekends to shoot and numerous outings on my own to complete. The pre-production and post-production stages were time-consuming but manageable. The production stage was exhilarating. I hardly slept for weeks. The major lesson I learned, however, was the importance of the distribution stage. This was where I dropped the ball, and it taught me a lot about myself and screenwriting. If there was one major take-away from the whole exercise, it would be not to neglect this component at any point of the production, from the beginning of the idea to the moment it is finally realized.
    </p>
</p>

export default synopsis;