import info from "./info";
import summary from "./summary";

import FilmPage from "../FilmPage";

const props = {
    title: info.title,
    type: info.type,
    bg: '50',
    summary: summary,
    link: '/my-head',
    videoId: '0OxwxdIBiDk',
    videoImage: '/assets/images/dad/images/video/myhead.jpg',
    channel: 'youtube'
}

export default FilmPage(props);
