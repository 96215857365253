import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

import PageHelmet from "../../components/Helmet";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Banner from "../../components/Banner";
import Video from "../../components/Video"
import LoglineAndSynopsis from "../../components/LoglineAndSynopsis";

function WritingPage(props) {
    return class PortfolioDetails extends Component {
        constructor () {
            super()
            this.state = {
              isOpen: false
            }
            this.openModal = this.openModal.bind(this)
        }
        openModal () {
            this.setState({isOpen: true})
        }
        render(){
            return(
                <React.Fragment>
                    
                    <PageHelmet pageTitle='Portfolio Details' />

                    <Header logo="symbol-dark" color="color-black"/>
                    
                    <Banner title={props.title} type={props.type} bg={props.bg} />

                    <LoglineAndSynopsis logline={props.logline} type={props.type} synopsis={props.synopsis} title={props.title}/>

                    <Video title={props.videoTitle} videoImage={props.videoImage} 
                    videoId={props.videoId} channel={props.channel} />

                    <Video title={props.trailerTitle} videoImage={props.trailerImage} 
                    videoId={props.trailerId} channel={props.channel} />

                    <Video title={props.makingTitle} videoImage={props.makingImage} 
                    videoId={props.makingId} channel={props.channel} />

                    {/* Start Back To Top */}
                    <div className="backto-top">
                        <ScrollToTop showUnder={160}>
                            <FiChevronUp />
                        </ScrollToTop>
                    </div>
                    {/* End Back To Top */}
                    
                    <Footer /> 

                </React.Fragment>
            )
        }
    }
}

export default WritingPage;
