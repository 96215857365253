import info from "./info";
import logline from "./logline";
import synopsis from "./synopsis";

import WritingPage from "../WritingPage";

const props = {
    title: info.title,
    type: info.type,
    bg: "42",
    link: '/the-present',
    logline: logline,
    synopsis: synopsis
}

export default WritingPage(props);
