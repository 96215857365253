import React, { Component } from "react";
import ModalVideo from 'react-modal-video'

class  Video extends Component {
    constructor (props) {
        super(props)
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <div className="rn-portfolio-details ptb--20 bg_color--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="portfolio-details">
                                        <div className="inner">
                                            <h2>{this.props.title || 'Video'}</h2>
                                            <div className="thumb position-relative mb--30">
                                                <img src={this.props.videoImage} alt="Portfolio Images"/>
                                                <ModalVideo channel={this.props.channel || 'vimeo'} isOpen={this.state.isOpen} videoId={this.props.videoId} onClose={() => this.setState({isOpen: false})} />
                                                <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        )
    }
}

export default Video;