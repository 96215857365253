import React from "react";

const synopsis = <p>

<p>
    Jake Thompson, an ex-fisherman has lost his pregnant wife and eight-year-old son in a ‘hit and run’ accident. The primary suspect is Winston Chow, son of Chung-li Chow, head of a Triad crime group known as “The White Python.”  They are involved heavily in prostitution and the illegal drug trade in Vancouver. Winston is released from custody and no other suspects are reported. Jake sets out to find the killer.  He begins with Winston Chow and never stops.Alex Wong is a junior lawyer whose little brother died of fentanyl poisoning. She takes on the pro bono case of Jake Thompson who has confessed to killing Winston Chow. He also claims to have gathered information on the White Python and their illegal opioid operation.  Alex knows she should pass this case on to a more experienced lawyer, but she fears a seasoned veteran would just opt for a plea bargain.  She wants the information to be exposed.  She wants justice for her brother. She decides to defend Jake herself. That is, until she finds out the money trail leads to the very law offices in which she works. 
</p>

<p>For Reginald Callow, the Assistant Attorney General, charging Jake is a delicate matter. No body has been found and the possible evidence that Jake has uncovered about the ‘laundering’ of drug money into the Vancouver real estate market could jeopardize his vast holdings in various shell corporations. He decides to delay laying charges so that Jake can be dealt with in a more covert manner by the White Python.</p>

<p>Meanwhile, things have gotten complicated for Jake ever since he approached Candace Chow, Winston Chow’s wife, for help to convict her husband.  Candace Chow had been drifting away from Winston for some years and now she sees in Jake an opportunity to break free from the criminal underworld for good. What she did not count on, however, is the intense attraction she’d feel for this broken man.  
Can Alex take this case to trial and avenge her little brother’s death? Did Candace tell Jake the truth about the hit and run? Who was driving the car that killed Jake’s family? It all comes back to that dark rainy night in Steveston, B.C., when Rebecca and Ben Thompson decided to take a short cut home.</p>

</p>

export default synopsis;