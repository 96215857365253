import info from "./info";
import summary from "./summary";

import FilmPage from "../FilmPage";

const props = {
    title: info.title,
    type: info.type,
    bg: '48',
    summary: summary,
    link: '/y',
    videoId: '525226929',
    videoImage: '/assets/images/dad/images/video/y.jpg'
}

export default FilmPage(props);