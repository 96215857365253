import React from "react";

function LoglineAndSynopsis(props) {
return <div className="rn-portfolio-details ptb--120 bg_color--1">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="portfolio-details">
                    <div className="inner">
                        <h2>{props.title}</h2>
                        <h4>{props.type}</h4>
                        <p className="subtitle">Logline</p>
                        <p>{props.logline}</p>
                        <p className="subtitle">Synopsis</p>
                        <p>{props.synopsis}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
}

export default LoglineAndSynopsis