import React from "react";

const synopsis = <p>

<p>Tom Davidson lives with his brother, Connor, in an old rundown rental home in Cordova Bay. He wants to move in with his girlfriend and her 4-year-old son. Both brothers play for the Lions rugby club on Vancouver Island and Tom has dreams of winning a rugby scholarship so he can attend university and study robotic engineering.  The trouble is, right now he’s a ‘framer‘ from Campbell River, struggling to pay his bills.</p>

<p>They both work for their teammate, Jim Takarangi, a large Maori from New Zealand. He is a family man, the captain of their rugby team, and the owner of the company where both brothers work. Jim is having troubles of his own. The housing market is plummeting, and he has invested his life savings in a project that is bleeding cash every day.</p>

<p>Then one day, Tom sees something out of the corner of his eye. It is Jim’s wallet lying open on the ground. He takes it home to return to Jim at practice but, that night, he and Conner discover in it a winning lottery ticket worth six million dollars - enough for both brothers to buy a new life, and the chance for Tom to go to school while supporting his girlfriend and their son.</p>

<p>Tom’s passion for sports collides with the cold logic of matter.  His brother wants to cash it in. He wants to wait. The Lions have just gotten into the play-offs and a win in the finals would be the pinnacle of achievement for the team. It would also be a great opportunity for Tom to be looked at for a scholarship.  The problem, of course, to perform at his highest level in rugby, knowing he’s ripped-off his boss, captain, and friend.</p>

<p>Elements more powerful than money surface. Tom’s dream of playing high level rugby, getting a scholarship at a good school, now feels like a pie-in-the-sky fantasy in comparison with the ease of cashing in the ticket and leaving town.  It’s a question of character and always has been.  Wounds from his difficult childhood undermine his confidence.  Loyalty to his brother and their love of sports provides a sanctuary. If only the choice was simple but that requires allegiance to a higher order, something both have always struggled with.</p>

<p>The competition in the finals for the island championships is the Bay Warriors, a team that has blossomed under professionalism and recruits players from far and wide. It’s a David versus Goliath struggle and the Lion’s chances of victory are slim, especially now that their key players are distracted.  Tom wants to play well but his guilt is affecting his resolve. Connor is partying hard and living the life while Jim’s business future is crumbling before him. If it wasn’t for rugby, they’d be totally lost but they still have their mates, their spirit, and the game they love, three factors that could make all the difference, and possibly change their lives forever, on a cold Saturday afternoon at Tilsbury Park.</p>

</p>

export default synopsis;