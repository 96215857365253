import React from "react";

const synopsis = <p>

<p>Set in the late fifties, Frank Alders is a young reporter, working his way up the ladder at a small newspaper called “the Gazette”. The paper has recently been swallowed up by media magnates, Bill and Tom Baker, who own numerous other papers, magazines and are looking to buy a TV station. They have an aggressive strategy that relies on sensationalism and half-truths to out-perform their competition.
Frank has always loved writing and needs this job, but he has difficulty with ‘yellow’ journalism. He would ultimately prefer to write novels. His latest fictional novel about the second coming of Christ is currently on his mind while he has been tasked to report on a mysterious fire that burned down the local arena. This boxing venue is located in the neighbourhood where he grew up and, “the Shamrocks”, a gang he used to run with, are being targeted for the crime by the paper.</p>

<p>Divided between loyalty and ambition, Frank is forced to choose between betraying his old mates or being fired from his job. His ex-buddies may be guilty, but his conscience collides with his cynicism over reporting unsubstantiated facts. This is when he stumbles over new information about his novel that challenges his outlook completely.</p>

<p>It is an incredible story that has the markings of a world-changing discovery, but it is being completely ignored.  Frank is hesitant to push it, knowing the path it will take him down but, the more he investigates, the clearer his choices become. The trouble is these choices aren’t easily made when it could mean walking away from everything he knows and loves.</p>

</p>

export default synopsis;