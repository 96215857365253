import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

import Breadcrumb from "../components/Breadcrumb";
import PageHelmet from "../components/Helmet";
import Header from "../components/Header";
import Footer from "../components/Footer"
import PortfolioList from "../components/PortfolioList";

class ViewAllPage extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Blog' />

                <Header logo="symbol-dark" color="color-black"/>
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'All Works'}   />
                {/* End Breadcrump Area */}

                {/* Start Blog Area */}
                <div className="rn-blog-area ptb--60 bg_color--1">
                    <div className="container">
                        <h1>All Works</h1>
                        <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="12" />
                        </div>
                    </div>
                </div>
                {/* End Blog Area */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default ViewAllPage;
