import React from "react";

function Banner(props) {
    let bg = '37'
    if (props.bg) {
        bg = props.bg
    }
    let className = "rn-page-title-area pt--120 pb--190 bg_image bg_image--" + bg
    return <div className={className}>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="rn-page-title text-center pt--100">
                        <h2 className="title theme-gradient"></h2>
                        {/* <p className="theme-p">{props.type}</p> */}
                    </div>
                </div>
            </div>
        </div>
    </div> 
};

export default Banner;
