import info from "./info";
import summary from "./summary";

import FilmPage from "../FilmPage";

const props = {
    title: info.title,
    type: info.type,
    bg: "44",
    link: '/ambition',
    summary: summary,
    videoId: '34527473',
    videoImage: '/assets/images/dad/images/video/ambition.jpg'
}

export default FilmPage(props);
