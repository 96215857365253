import React, { Component } from "react";
import {Link} from "react-router-dom";

import AllContent from "../content/AllContent";

export class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = AllContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href={value.link}>{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={value.link}>View Details</a>
                                    </div>
                                </div>
                            </div>
                            <Link className="link-overlay" to={value.link}></Link>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}

export default PortfolioList;