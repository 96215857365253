import info from "./info";
import logline from "./logline";
import synopsis from "./synopsis";

import FatherAndSonPage from "../FatherAndSonPage";

const props = {
    title: info.title,
    type: info.type,
    bg: '38',
    link: '/father-and-son',
    logline: logline,
    synopsis: synopsis,

    videoId: '221791604',
    trailerId: '150486970',
    makingId: '154260005',

    videoTitle: 'The Film',
    trailerTitle: 'The Trailer',
    makingTitle: 'The Making Of',

    videoImage: '/assets/images/dad/images/video/fatherandson.jpg',
    trailerImage: '/assets/images/dad/images/video/fatherandson_trailer.jpg',
    makingImage: '/assets/images/dad/images/video/fatherandson_making.jpg'
}

export default FatherAndSonPage(props);
