import info from "./info";
import summary from "./summary";

import FourthWallPage from "../FourthWallPage";

const props = {
    title: info.title,
    type: info.type,
    bg: "49",
    link: '/the-fourth-wall',
    summary: summary,
    videoId: 'OrL2y2hQWHo',
    videoImage: '/assets/images/dad/images/video/fourthwall.jpg',
    channel: 'youtube'
}

export default FourthWallPage(props);
